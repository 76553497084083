@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=block");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base {
  .table {
    @apply w-full overflow-hidden rounded table-auto;
  }
  .table thead tr {
    @apply text-center bg-opacity-75 bg-gray-200;
  }
  .table tbody tr {
    @apply bg-gray-50 even:bg-gray-100 hover:bg-gray-200 text-center;
  }
  .table th {
    @apply py-2;
  }
  .table td {
    @apply py-[5px];
  }
}

@layer utilities {
  .flex-cc {
    @apply flex items-center justify-center;
  }
  .flex-cs {
    @apply flex items-start justify-center;
  }
  .flex-ce {
    @apply flex items-end justify-center;
  }

  .flex-bc {
    @apply flex items-center justify-between;
  }
  .flex-bs {
    @apply flex items-start justify-between;
  }
  .flex-be {
    @apply flex items-end justify-between;
  }

  .flex-sc {
    @apply flex items-center justify-start;
  }
  .flex-ss {
    @apply flex items-start justify-start;
  }
  .flex-se {
    @apply flex items-end justify-start;
  }

  .flex-ec {
    @apply flex items-center justify-end;
  }
  .flex-es {
    @apply flex items-start justify-end;
  }
  .flex-ee {
    @apply flex items-end justify-end;
  }

  .flex-vc {
    @apply flex items-center justify-evenly;
  }
  .flex-vs {
    @apply flex items-start justify-evenly;
  }
  .flex-ve {
    @apply flex items-end justify-evenly;
  }

  .flex-ac {
    @apply flex items-center justify-around;
  }
  .flex-as {
    @apply flex items-start justify-around;
  }
  .flex-ae {
    @apply flex items-end justify-around;
  }

  .fixed-top {
    @apply fixed top-0 right-0 left-0 z-[1030];
  }

  .fixed-bottom {
    @apply fixed bottom-0 right-0 left-0 z-[1010];
  }

  .gallery-container::-webkit-scrollbar {
    display: none;
  }

  .no-scroll-bar-all::-webkit-scrollbar {
    display: none;
  }

  .no-scroll-bar-all {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* clears the 'X' from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  .image-upload > input {
    display: none;
  }
}
